.cardContainer{

   background: rgb(2,0,36);
background: linear-gradient(184deg, rgba(2,0,36,1) 0%, rgba(219,219,232,0.2582282913165266) 50%, rgba(0,0,0,1) 100%);
}

.card{
    display:flex;
    border-radius: 3px;
    text-decoration: none;
}
.cardImgHeight{
   background-size: contain;
   background-color:"#212529";
   background-repeat: no-repeat;
   background-blend-mode: multiply;
  } 
.text{
   position:absolute;
   background: rgb(255,255,255);
    background: linear-gradient(184deg, rgba(255,255,255,0) 0%, rgba(21,21,21,0.2582282913165266) 55%, rgba(0,0,0,1) 100%);
   color: #F5F5F5;
  bottom: 0;
   width:100%;
   height:50%;
   font-size: 3vh;
   font-family: 'DM Serif Display';
   
   
};

@media only screen 
and (min-device-width: 280px) 
and (max-device-width: 600px) 
and (orientation: portrait) {
   .cardImgHeight{
    height: 300px;
   } 
}

@media only screen 
and (min-device-width: 601px) 
and (max-device-width: 658px) 
and (orientation: portrait) {
   .cardImgHeight{
    height: 16em;
   } 
}


@media only screen 
and (min-device-width: 659px) 
and (max-device-width: 851px) 
and (orientation: portrait) {
   .cardImgHeight{
    height: 350px;
   } 
}


@media only screen 
and (min-device-width: 2559px) 
and (max-device-width: 2600px) 
and (orientation: portrait) {
   .cardImgHeight{
    height: 850px;
   } 
}