.preloader{
    height: 100vh;
    width: 100%;
    background: black;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 1080;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.preloader::-webkit-scrollbar {
    display: none;
}
.preloader{
    
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      
}
.content{
    display:flex;
    align-items: center;
}
.svg {
    fill: none;
    stroke:white;
    stroke-dasharray: 120 480;
    stroke-dashoffset: 120;
    transition: 3s ease-in-out;
  }