@import url('https://fonts.cdnfonts.com/css/dm-serif-display');
.content{
    justify-content: center;
    gap: .9rem;
display: grid;
grid-template-areas: 
"a a d d"
"b b d d"
"c c d d"
"c c d d"
"c c d d"
"q q e e"
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
" . . . ."
". . . ."
"f f f f"
". . . ."
"g g g g"
}
.a{
    width: 400px;
    grid-area: a;
}
.Para1_2{
    width: 400px;
    grid-area: b;
}
.Para3_5{
    width: 400px;
    grid-area: c;
}
.Para5_6{
    width: 400px;
    grid-area: q;
  
}
.Profile{
    padding-top: 20px;
    grid-area: d;
}
.QuoteContainer{
    padding-top: 20px;
    grid-area: e;
}
.Clients{
   grid-area: f;
}
.formContainer{
    grid-area: g;
}
.main{
    justify-content: center;
    display: grid;
    margin: auto;
    gap: 4rem;
    grid-template-areas: 
    "first second"
    "first second";
}
.formbox{
    grid-area: first;
}
.formContainer{
    display: flex;
    justify-content: center;    
}
.info{
    grid-area: second;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
}
.header{
   font-size: 27px;
   font-weight:600;
   font-family:dm-serif-display;
   text-align: left;
}
.info{
    font-size: 17px;
    font-family:dm-serif-display;
    text-align: left;
    
}
.formContainer{

    font-family:dm-serif-display;

}
.profilePic{
    width: 420px;
}
.Quote{
    width: 420px;
}
.clients_and_partnersD{
 width: 840px;
 height: 200px;
}
.clients_and_partnersM{
    display: none;

}

.form{
    display: grid;
    gap: 0.7rem;

    width: 600px;
    grid-template-areas:
    "b b b b"
    "c c c c"
    "d d d d"
    "e e e e"
    "f . . ."
    ;
}

.firsName{
    grid-area: a;
     
}
.lastName{
    grid-area: b;
}
.mail{
    grid-area: c;
    
}
.subj{
    grid-area: d;
    
}
.textarea{
    grid-area: e;
    width: 100%;
    
}
.submit{
    height: 40px;
    width: 70px;
    grid-area: f;
    background-color: #1E3240;
    padding: 10px;
    color: white;

}
.form{
    margin: 40px auto;
}
.tags{
    display: flex;
    flex-direction: column;
}



@media only screen 
  and (min-device-width: 210px) 
  and (max-device-width: 300px) 
  and (orientation: portrait) {
    .mainBlock{
        display: flex;
        justify-content: center;
    }
    .content{
        justify-content: center;
        display: grid;
        gap: 1.2rem;
        grid-template-areas:
        
        " d d"
        " a a"
        " b b"
        " e e"
        " c c"
        " q q"
        " f f"
        "g g"
        ;
    }
    .main{
          justify-content: center;
        display: grid;
        gap: 2rem;
        grid-template-areas: 
        "second second"
        " first first"
        ;
    }
    .form{

        justify-content: center;
        gap: 0.5rem;
        margin: 0;
        grid-template-areas:

    "b b"
    "c c"
    "d d"
    "e e"
    "f .";
    }
.a{
    width: 190px;
}
.Para1_2{
    width: 200px;
}
.Para3_5{
    width: 200px;
}
.Para5_6{
    width: 200px;
}
.clients_and_partnersD{
    display: none;
}
.clients_and_partnersM{
    width: 250px;
    display: block;
}
.Quote{
    padding-top:0;
    width: 230px;
}
.profilePic{
    padding-top:0;
    width: 220px;
}
.header{
    width: 200px
}
.info{
    width: 200px;
}
.form{
    width: 220px;
}
.firstName{
    width: 210px;
}
.lastName{
    margin-right: 4px;
    margin-left: 0;
}
.mail{
    margin-right: 4px;
}
.subj{
    margin-right: 4px;
}
  }


@media only screen 
  and (min-device-width: 310px) 
  and (max-device-width: 570px) 
  and (orientation: portrait) {
    .mainBlock{
        display: flex;
        justify-content: center;
    }
    .content{
        justify-content: center;
        display: grid;
        gap: 1.2rem;
        grid-template-areas:
        
        " d d"
        " a a"
        " b b"
        " e e"
        " c c"
        " q q"
        " f f"
        "g g"
        ;
    }
    .main{
        justify-content: center;
      display: grid;
      gap: 2rem;
      grid-template-areas: 
      "second second"
      " first first"
      ;
  }
  .form{

      justify-content: center;
      gap: 0.5rem;
      margin: 0;
      grid-template-areas:
  "b b"
  "c c"
  "d d"
  "e e"
  "f .";
  }
.a{
    width: 290px;
}
.Para1_2{
    width: 290px;
    
}
.Para3_5{
    width: 300px;
}
.Para5_6{
    width: 300px;
}
.clients_and_partnersD{
    display: none;
}
.clients_and_partnersM{
    width: 290px;
    display: block;
}
.Quote{
    padding-top:0;
    width: 290px;
}
.profilePic{
    padding-top: 0;
    width: 290px;
}
.header{
    width: 290px
}
.info{
    width: 290px;
}
.form{
    width: 290px;
}
.firstName{
    width: 281px;
}
.lastName{
    margin-right: 4px;
    margin-left: 0;
}
.mail{
    margin-right: 4px;
}
.subj{
    margin-right: 4px;
}
  }

  @media only screen 
  and (min-device-width: 700px) 
  and (max-device-width: 1000px) 
  and (orientation: portrait) {
   
    .content{
        justify-content: center;
        display: grid;
        gap: 0.5rem;
        grid-template-areas:
        "a a d d"
        "b b d d"
        ". . . ."
        ". . . ."
        ". . . ."
        "e e e e"
        ". . . ."
        ". . . ."
        ". . . ."
        "c c c c"
        "q q q q"
    
        "f f f f"
        "g g g g"
        ;
    }
    .profilePic{
        padding-top:0;
        width: 370px;
    }
     .a{
        width: 315px;
     }
     .header{
        font-size: 28px;
     }
    .Para1_2{
        width:280px;
        font-size: 19px;
    }
    .Para3_5{
        width:700px;
        font-size: 19px;
    }
    .Para5_6{
            width:700px;
            font-size: 19px;      
    }
    .Quote{
        padding-top: 0;
        width: 700px;
        height: 250px;
    }
    .clients_and_partnersD{
        width:700px;
    }
    .main{
        gap: 8rem;
    }
    .form{
        width: 290px;
    }
    .lastName{
        margin-left: 0px;
        margin-right: 4px;
    }
    .mail{
        margin-right: 4px;
    }
    .subj{
        margin-right: 4px;
    }

  }
  
  @media only screen 
  and (min-device-width: 1430px) 
  and (max-device-width: 2600px)
  {
   .a{
     width: 500px;
   }
    .header{
        font-size: 37px;
     }
     .Para1_2{
        width: 500px;
        font-size: 22px;
     }
     .Para3_5{
        width: 500px;
        font-size: 22px;
     }
     .Para5_6{
        
            width: 500px;
            font-size: 22px;
     }
     .profilePic{
        width: 35rem;
        height: 50rem;
     }
     .Quote{
        width: 35rem;

     }
     .clients_and_partnersD{
        width: 68rem;
        height: 20rem;
     }
  }