form{
    display: grid;
    margin: auto;
    gap: 1rem;
    max-width: 500px;
    grid-template-areas:
    "a a b b"
    "c c c c"
    "d d d d"
    "e e e e"
    "f . . ."
    ;
}
.main{
    display: grid;
    margin: auto;
    gap: 0.5rem;
    grid-template-areas: 
    "first second"
    "first second";
}

.firsName{
    grid-area: a;
     
}
.lastName{
    grid-area: b;
    margin-left: -58px;
}
.mail{
    grid-area: c;
    
}
.subj{
    grid-area: d;
    
}
.textarea{
    grid-area: e;
    width: 100%;
    
}
.submit{
    height: 40px;
    width: 70px;
    grid-area: f;
    background-color: #1E3240;
    padding: 10px;
    color: white;

}
form{
    margin: 20px auto;
}
.formbox{
    grid-area: first;
}
.info{
    grid-area: second;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}
.tags{
    display: flex;
    flex-direction: column;
}
