.a{
   text-decoration: none;
   border-radius: 1px;
   color: inherit;
   font-family:dm-serif-display;
}

.center {
    
    height: 60px;
    position: "";
  }
  
  .btn {
    font-family:dm-serif-display;
    color: #ffffff;
    width: 110px;
    height: 50px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 2px;
    outline: none;
    transition: .5s ease-in-out;
  }
  
  .svg {
    position: "";
    fill: none;
    stroke:white;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: .5s ease-in-out;
    background: #cfcece;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }