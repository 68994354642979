.gridContainer{
  justify-content: center;
 display: grid;
 width: 50%;
 gap: 0.8rem;
 margin: auto;
 grid-template-areas:
 "a a b b"
 "a a b b"
 "c c c c"
 "c c c c"
 "d d e e"
 "d d e e"
 "f f f f"
 "f f f f"
 ;

}


.item1{
  grid-area: a;
}
.item2{

  grid-area: b;
}
.item3{
  grid-area: c;
}
.item4{
 grid-area: d;
}
.item5{
  grid-area: e;
 }
 .item6{
  grid-area: f;
 }





.footer{
  grid-area: L;
}

.imgGrid{
  height: 600px;
  overflow: hidden;
}




@media only screen 
and (min-device-width: 280px) 
and (max-device-width: 650px) 
and (orientation: portrait) {
  .imgGrid{
width: 100%;
  }

  .gridContainer{
    width: 100%;
    grid-template-areas: 
    "a a"
    "b b"
    "c c"
    "d d"
    "e e"
    "f f";
  }
  .imgGrid{
    height:"400px";
  }
}
